/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link } from "gatsby";
import QuestionAnswer from "@material-ui/icons/QuestionAnswer";
import Web from "@material-ui/icons/Web";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import Home from "@material-ui/icons/Home";
import PlayCircleFilled from "@material-ui/icons/PlayCircleFilled";
import Description from "@material-ui/icons/Description";

import ListIcon from "@material-ui/icons/List";
import People from "@material-ui/icons/People";
import Assignment from "@material-ui/icons/Assignment";
import PermContactCalendar from "@material-ui/icons/PermContactCalendar";
import Chat from "@material-ui/icons/Chat";
import Call from "@material-ui/icons/Call";
import ViewCarousel from "@material-ui/icons/ViewCarousel";
import AccountBalance from "@material-ui/icons/AccountBalance";
import ArtTrack from "@material-ui/icons/ArtTrack";
import ViewQuilt from "@material-ui/icons/ViewQuilt";
import LocationOn from "@material-ui/icons/LocationOn";
import Fingerprint from "@material-ui/icons/Fingerprint";
import AttachMoney from "@material-ui/icons/AttachMoney";
import Store from "@material-ui/icons/Store";
import AccountCircle from "@material-ui/icons/AccountCircle";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Layers from "@material-ui/icons/Layers";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import LineStyle from "@material-ui/icons/LineStyle";
import Error from "@material-ui/icons/Error";
import { motion } from "framer-motion";
// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";
import Settings from "@material-ui/icons/Settings";
import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";
import Covid from "assets/img/svg/covidicon.svg";
import Covid2 from "assets/img/svg/covidicon2.svg";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const smoothScroll = (e, target) => {
    if (window.location.pathname === "/sections") {
      var isMobile = navigator.userAgent.match(
        /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
      );
      if (isMobile) {
        // if we are on mobile device the scroll into view will be managed by the browser
      } else {
        e.preventDefault();
        var targetScroll = document.getElementById(target);
        scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
      }
    }
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function() {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };
  var onClickSections = {};

  const { dropdownHoverColor } = props;
  const classes = useStyles();
  const active={
    fontWeight: "bold",
      backgroundColor:"#e91e63",
      textDecoration:"underline",
      minWidth:'auto',
      minHeight:'auto',
    textAlign:'center',
    borderRadius: '30px',
      color:"#ffffff"
  };
  return (
    <List className={classes.list + " " + classes.mlAuto}>
      <ListItem className={classes.listItem}>
       
           <motion.div whileHover={{
   scale: 1.0 ,
    backgroundColor: "#ff89c4",
    borderRadius: '30px',
    fontWeight:'800'
 
  }}>
            <Link to="/"   className={classes.navLink} activeStyle={active}>
     
     <Home className={classes.navIcons} /> Home
         
        </Link>
        </motion.div>
        </ListItem>
        <ListItem className={classes.listItem}>


       
       <motion.div whileHover={{
scale: 1.0 ,
backgroundColor: "#ff89c4",
borderRadius: '30px',
fontWeight:'800'

}}>
        <Link to="/#buslocfeatures"   className={classes.navLink} activeStyle={active}>
 
 <Settings className={classes.navIcons} /> Features
     
    </Link>
    </motion.div>
    </ListItem>
        <ListItem className={classes.listItem}>

        <motion.div whileHover={{
   scale: 1.0 ,
    backgroundColor: "#ff89c4",
    borderRadius: '30px',
 
  }}>
             <Link to="/schoolsintrested"   className={classes.navLink} activeStyle={active}>
     
             <LineStyle className={classes.navIcons} /> Get Started
                 
                </Link>
                </motion.div>
                </ListItem>
                <ListItem className={classes.listItem}>
                <motion.div whileHover={{
   scale: 1.0 ,
    backgroundColor: "#ff89c4",
    borderRadius: '30px',
 
  }}>
             {/*     <Link to="/takeatour"  className={classes.navLink} activeStyle={active}>
     
                 <PlayCircleFilled className={classes.navIcons} />Tour
                     
                    </Link>
                    </motion.div>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                    <motion.div whileHover={{
   scale: 1.0 ,
    backgroundColor: "#ff89c4",
    borderRadius: '30px',
 
  }}> */}
                     <Link to="/scheduleademo"  className={classes.navLink} activeStyle={active}>
     
                     <Description className={classes.navIcons} />  schedule demo
                         
                        </Link>
                        </motion.div>
                        </ListItem>
                        <ListItem className={classes.listItem}>
                        <motion.div whileHover={{
   scale: 1.0 ,
    backgroundColor: "#ff89c4",
    borderRadius: '30px',
 
  }}>
                          <Link to="/partners"  className={classes.navLink} activeStyle={active}>
     
                          <People className={classes.navIcons} /> Partners
                              
                             </Link>
                             </motion.div>
                             </ListItem>
                             <ListItem className={classes.listItem}>
                             <motion.div whileHover={{
   scale: 1.0 ,
    backgroundColor: "#ff89c4",
    borderRadius: '30px',
 
  }}>
                             <Link to="/contactus"  className={classes.navLink} activeStyle={active}>
     
                             <PermContactCalendar className={classes.navIcons} /> Contact Us
                                 
                                </Link>
                                
              
           </motion.div>
       
      </ListItem>
      <ListItem className={classes.listItem}>
      <motion.div whileHover={{
   scale: 1.0 ,
    backgroundColor: "#ff89c4",
    borderRadius: '30px',
 
  }}>
                             <Link to="/blogs"  className={classes.navLink} activeStyle={active}>
     
                             <Web className={classes.navIcons} /> Blogs
                                 
                                </Link>
                                </motion.div>
                                
              
           
       
      </ListItem>
      <ListItem className={classes.listItem}>
      <motion.div whileHover={{
   scale: 1.0 ,
    backgroundColor: "#ff89c4",
    borderRadius: '30px',
 
  }}>
                             <Link to="/covid"  className={classes.navLink} activeStyle={active}>
                             <img src={Covid2} className={classes.navIcons} />
     
                              COVID-19
                                 
                                </Link>
                                </motion.div>
                                
              
           
       
      </ListItem>
      <ListItem className={classes.listItem}>
      <motion.div whileHover={{
   scale: 1.0 ,
    backgroundColor: "#ff89c4",
    borderRadius: '30px',
 
  }}>
                             <Link to="/faq"  className={classes.navLink} activeStyle={active}>
     
                             <QuestionAnswer className={classes.navIcons} /> FAQ'S
                                 
                                </Link>
                                </motion.div>
                                
              
           
       
      </ListItem>
     {/*  <ListItem className={classes.listItem}>
    <Button type="button" style={{fontWeight:600}} color="rose" round size="sm" href="https://cp.busloc.io/auth/signup">
     
        Sign up
    
      </Button>
    </ListItem>
    <ListItem className={classes.listItem}>
    <Button type="button" style={{fontWeight:600}} color="rose" size="sm" round href="https://cp.busloc.io/auth/signin">
     
        Sign in
    
      </Button>
    </ListItem> */}
 
    </List>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ])
};
