/*eslint-disable*/ import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import {Helmet} from "react-helmet";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.js";



const useStyles = makeStyles(landingPageStyle);

export default function LandingPage({ children,...rest }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Helmet>
     
     <html lang="en-US"/>
   
    {/*  <meta charset="UTF-8"/> */}
     
    {/*  <meta http-equiv="Cache-control" content="public, max-age=0, must-revalidate"/> */}

    {/*    <link href="https://use.fontawesome.com/releases/v5.0.10/css/all.css"
     rel="stylesheet"/>
     <link
     rel="stylesheet"
     type="text/css"
     href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Material+Icons"
   />
   <link
     href="https://fonts.googleapis.com/icon?family=Material+Icons"
     rel="stylesheet"
   />
*/}



 </Helmet>
      <Header
        color="info"
        brand="BUSLOC"
        links={<HeaderLinks dropdownHoverColor="info" />}
        
        changeColorOnScroll={{
          height: 500,
          color: "info"
        }} 
        {...rest}
      />
     {children}
   
      <Footer
       theme="dark"
        content={
          <div>
            <div className={classes.left}>
              <List className={classes.list}>
               {/*  <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.creative-tim.com/?ref=mkpr-landing"
                    target="_blank"
                    className={classes.block}
                  >
                    Creative Tim
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.creative-tim.com/presentation?ref=mkpr-landing"
                    target="_blank"
                    className={classes.block}
                  >
                    About us
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="//blog.creative-tim.com/" className={classes.block}>
                    Blog
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a
                    href="https://www.creative-tim.com/license?ref=mkpr-landing"
                    target="_blank"
                    className={classes.block}
                  >
                    Licenses
                  </a>
                </ListItem> */}
              </List>
            </div>
            <div className={classes.left}>
                &copy; {1900 + new Date().getYear()} {/* , made with{" "}
                <Favorite className={classes.icon} /> by - {" "} */}
                BUSLOC
              </div>
            </div>
          
        }
      />
    </div>
  );
}
